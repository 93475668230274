import request from "@/utils/request";

// 日志列表
export function clientLogListPage(query) {
    return request({
        url: "/client/log/clientLogListPage",
        method: "get",
        params: query,
    });
}

// 详情
export function userInsertApi(logId) {
    return request({
        url: `/client/log/clientLogSelectById/${logId}`,
        method: "get",
    });
}

// 产品详情
export function getSoftwareInfo(softwareId) {
    return request({
        url: `/client/software/softwareSelectById/${softwareId}`,
        method: "get",
    });
}
