<!--
* @Description 用户支持-更新日志
* @fileName index.vue
* @MethodAuthor stjh
* @Date 2023-06-30 17:20:54
-->
<template>
  <div class="aboutContainer">
    <div class="top">
      <div class="titlecon">
        <p>{{ softwareData.name }}</p>
        <p>{{ softwareData.alias }}</p>
      </div>
    </div>
    <div class="con">
      <h1 class="updateTitle">{{ $t("updateLog.section1_title") }}</h1>
      <div class="box" @scroll="watchScroll">
        <div class="conBox" v-for="(item, index) in list" :key="index">
          <div class="line"></div>
          <div class="flexBox">
            <span class="conTitle">
              <span>{{ item.logTitle }}</span
              >&nbsp;
              <span>（{{ item.updateTime }}）</span>
            </span>
            <span class="conContent" @click="toDetail(item.logId)"
              >{{ $t("common.KnowMore") }}</span
            >
          </div>
        </div>
        <div class="font-color-gray-999999 text-align-center">
          {{
            list.length == 0
              ? $t(`common.NoData`)
              : loading && !finished
              ? $t(`common.Loading`)
              : total > 99
              ? $t(`common.NoMore`)
              : ""
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clientLogListPage, getSoftwareInfo } from "@/api/log.js";
export default {
  data() {
    return {
      //
      loading: false,
      finished: true,
      switch: false, //加锁，防止滚动时，判断条件重复调用

      title: "",
      list: [],
      softwareData: null,

      queryParams: {
        pageNum: 1,
        pageSize: 99,
      },
      total: 0,
    };
  },
  created() {
    this.getList();
    this.getSoftwareInfo();
  },
  mounted() {},
  methods: {
    getSoftwareInfo() {
      getSoftwareInfo(this.$route.query.softwareId).then((response) => {
        if (response.code == 200) {
          this.softwareData = response.data
        }
      });
    },
    getList() {
      this.loading = true;
      let params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        softwareId: this.$route.query.softwareId,
      };
      clientLogListPage(params).then((response) => {
        this.loading = false;
        if (response.code == 200) {
          this.title = response.rows[0].logTitle;
          if (response.rows && response.rows.length > 0) {
            this.list = this.list.concat(response.rows);
            this.total = response.total;
            if (
              response.rows.length < this.queryParams.pageSize ||
              this.list.length >= this.total
            ) {
              this.finished = true;
            } else {
              this.finished = false;
              this.queryParams.pageNum += 1;
              this.switch = false;
            }
          }
        }
      });
    },

    // 监听滚动事件
    watchScroll(e) {
      let scrollTop = e.target.scrollTop;
      let clientHeight = e.target.clientHeight;
      let scrollHeight = e.target.scrollHeight;
      let saveHeight = 30; //安全距离，距底部xx
      let tempVal = scrollTop + clientHeight + saveHeight;

      if (tempVal >= scrollHeight) {
        console.log("滚动到底了");
        if (!this.finished && !this.switch) {
          this.getList();
        }
        this.switch = true;
      } else {
        console.log("还没有滚动到底");
      }
    },

    toDetail(id) {
      // this.$router.push({ path: "/updateLogDetail", query: { id: id } });
      this.$uniFun.openUrl(`/dnnweb/updateLogDetail?id=${id}`)
    },
  },
};
</script>
<style scoped>
.box::-webkit-scrollbar {
  display: none;
}
</style>
<style lang="scss" scoped>
.aboutContainer {
  background: #fff;
  .top {
    width: 100%;
    height: 400px;
    // background: #b6bfd6;
    background-image: url("~@/assets/images/userImg/EIcad-2@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .titlecon {
      width: 999px;
      line-height: 1.5;
      margin: 0 auto;
      padding-top: 144px;

      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #643b10;
    }
  }

  .con {
    width: 1200px;
    padding-bottom: 300px;
    margin: 0 auto;
    text-align: center;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1d1d1f;
    .updateTitle {
      font-size: 40px;
      padding: 48px 0;
    }
    .box {
      max-height: 700px;
      overflow: auto;

      .conBox {
        width: 999px;
        height: 80px;
        line-height: 80px;
        background: #f8f8f8;
        margin: 0 auto;
        margin-bottom: 48px;
        display: flex;
        .flexBox {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
        .conTitle {
          font-size: 24px;
        }
        .conContent {
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(29, 29, 31, 0.6);
          cursor: pointer;

          float: right;
          padding-right: 16px;
        }
      }

      .conBox:last-child {
        margin-bottom: 312px;
      }
      .line {
        width: 2px;
        height: 64px;
        background: #ff9413;
        margin: 8px 0;
        margin-right: 38px;
      }
    }
  }
}
</style>